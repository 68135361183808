// import { test } from './utils.es6';

// let b = "Hello";
// let a = `${b}, World!`;

const loc = window.location;


$(() => {
    console.log("DOM ready");

    // console.log(a, 2 + 3);

    // test();

});
